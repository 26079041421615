@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400&display=swap");

:root {
  --main-color-warning: #fa8500;
  --main-color-success: #0db100;
  --main-color-info: #3c78bc;
  --main-color-info-secondary-background: #e6f1f2;
  --main-color-info-secondary-accent: #1468fa;
  --main-color-error: #e10303;
  --main-color-danger: #e10303;
  --body-font: "Montserrat", sans-serif;

  --main-color-light-bg: #fafcfd;
  --main-color-palette: #c1fcf5;
  --main-color-dark-bg: #009a8f;
  --color-palette-1: #92006c;
  --color-palette-2: #ffe5fe;
  --color-palette-3: #9298cf;
}

body {
  overflow-x: hidden !important;
  background-color: var(--main-color-light-bg) !important;
}

.ChipMine-warning {
  color: var(--main-color-warning) !important;
  background-color: #fcd8af !important;
  border-color: transparent !important;
  height: 35px !important;
  max-width: 110px !important;
}
.ChipMine-success {
  color: var(--main-color-success) !important;
  background-color: #caffc6 !important;
  border-color: transparent !important;
  height: 35px !important;
  max-width: 110px !important;
}
.ChipMine-success * {
  color: var(--main-color-success) !important;
  border-color: transparent !important;
}
.ChipMine-error {
  
  color: var(--main-color-error) !important;
  background-color: #fcafaf !important;
  border-color: transparent !important;
  height: 35px !important;
  max-width: 110px !important;
}
.ChipMine-error * {
  color: var(--main-color-error) !important;
  border-color: transparent !important;
}
.ChipMine-danger {
  color: var(--main-color-danger) !important;
  background-color: #f1fa78 !important;
  border-color: transparent !important;
  height: 35px !important;
  max-width: 110px !important;
}
.ChipMine-secondary {
  color: var(--color-palette-1) !important;
  background-color: #f6d3ff !important;
  border-color: transparent !important;
  height: 35px !important;
  max-width: 110px !important;
}
.ChipMine-secondary * {
  color: var(--color-palette-1) !important;  
  border-color: transparent !important;
}

.ChipMine-info {
  color: var(--main-color-info-secondary-accent) !important;
  background-color: #ccdfff !important;
  border-color: transparent !important;
  height: 35px !important;
  max-width: 110px !important;
}
.ChipMine-info * {
  color: var(--main-color-info-secondary-accent) !important;  
  border-color: transparent !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-arrow {
  cursor: default !important;
}
.cursor-wait {
  cursor: wait !important;
}
.font-size-11 {
  font-size: 11px !important;
}
.font-size-25 {
  font-size: 25px !important;
}

.demo-editor {
  border: rgb(207, 207, 207) 1px solid !important;
}

.bg-info {
  background-color: var(--main-color-info) !important;
}

.border-none {
  border: none !important;
}

.border-just-bottom {
  box-shadow: none !important;
  border: none !important;
  border-radius: 5px !important;
  border-bottom: var(--main-color-info) 2px solid !important;
}
.border-success {
  border: var(--main-color-success) 2px solid !important;
}
.border-info{
  border: var(--main-color-info) 2px solid !important;
}

* {
  font-family: var(--body-font);
  font-weight: normal !important;
}

.h-60-px {
  height: 60px !important;
 
}


.h-90 {
  max-height: 90% !important;
  height: 90% !important;
}

.h-80 {
  max-height: 80% !important;
  height: 80% !important;
}

.h-70 {
  max-height: 70% !important;
  height: 70% !important;
}

.h-65 {
  max-height: 65% !important;
  height: 65% !important;
}

.h-60 {
  max-height: 60% !important;
  height: 60% !important;
}

.bg-lightBlue {
  background-color: var(--main-color-light-bg);
}
.bg-lightGreen {
  background-color: #b6fdb1 !important;
}

.bg-superlight {
  background-color: rgba(224, 233, 231, 0.96) !important;
}

.color-superlight {
  color: rgba(224, 233, 231, 0.96) !important;
}
.bg-Success {
  background-color: var(--main-color-success) !important;
}

.bg-Info .col-lg-11-mine {
  flex: 0 0 auto;
  width: 95% !important;
}

.col-1-mine {
  flex: 0 0 auto;
  width: 3% !important;
}

a {
  text-decoration: none !important;
  
}

.center-sm-left-lg {
  justify-content: left !important;
  align-content: left !important;
  display: flex !important;
}

@media (max-width: 575.98px) {
  

  .center-sm-left-lg {
    justify-content: center !important;
    align-content: center !important;
    display: flex !important;
  }
}

.card-footer {
  background: none !important;
  border: none !important;
}

.shadow-Info {
  box-shadow: 0 0.25rem 0.35rem #96c7ff !important;
}

.shadow-Info-light {
  box-shadow: 0 0.25rem 0.25rem var(--main-color-light-bg) !important;
}

.color-middleBlue {
  color: lightskyblue !important;
}

.color-middleGray {
  color: darkgray !important;
}

.color-info {
  color: var(--main-color-info) !important;
}

.color-warning {
  color: var(--main-color-warning) !important;
}

.color-success {
  color: var(--main-color-success) !important;
}

.color-success * {
  color: var(--main-color-success) !important;
}

.rounded-15 {
  border-radius: 15px !important;
}

.rounded-20 {
  border-radius: 20px !important;
}

.btn-info-full {
  border-radius: 10px;
  background: var(--main-color-info) !important;
  border: none !important;
  color: white;
  font-family: var(--body-font);
  height: 35px !important;
}

.btn-info-full:hover {
  border-radius: 10px;
  background-image: linear-gradient(
    to left,
    rgb(112, 112, 250),
    rgba(11, 12, 159, 0.96)
  );
  border-color: white;
  color: white;
}

.bg-white-shadowInfo {
  background-color: white !important;
  box-shadow: 0 0.25rem 0.35rem #87868a !important;
  border-radius: 15px !important;
}
.bg-white-shadowInfo * {
  color: #3d03e1 !important;
}
.bg-white-shadowInfo .card-header * {
  font-size: 70px !important;
}
.bg-white-shadowInfo:hover {
  box-shadow: 0 0.25rem 0.35rem #3d03e1 !important;
}

.color-mediumGray {
  color: #909090 !important;
}

.hover-info:hover {
  background-color: var(--main-color-info) !important;
}

.hover-info:hover * {
  color: white !important;
}

.hover-success:hover {
  background-color: var(--main-color-success) !important;
}

.hover-success:hover * {
  color: white !important;
}

.w-90 {
  max-width: 90% !important;
  min-width: 90% !important;
}
.text-justify {
  text-align: justify !important;
}
.text-Success{
  color: var(--main-color-success) !important;
}
.text-Info{
  color: var(--main-color-info) !important;
}
.text-Danger{
  color: var(--main-color-danger) !important;
}
.text-Warning{
  color: var(--main-color-warning) !important;
}
.color-info * {
  color: var(--main-color-info) !important;
}

.h-30-px {
  height: 30px !important;
}

.card-header {
  background-color: transparent !important;
  border: none !important;
}

.bg-transparent {
  background-color: transparent !important;
}
.glass-panel {
  margin: 40px auto;
  background-color: rgba(255, 255, 255, 0.668);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 32px;
  backdrop-filter: blur(2px);
}
.glass-panel-loading {
  color: #fff;
  margin: 0;
  background-color: rgba(103, 112, 105, 0.287);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1px;
  padding: 0;
  backdrop-filter: blur(10px);
}


#container-PlantillasReportes,
#container-officeHome,
#container-officeEditUser {
  margin-top: 5.5rem !important;
  padding-top: 3rem !important;
  justify-content: center !important;
  align-content: center;
  display: flex !important;
}

.Mui-selected {
  background-color: rgba(103, 112, 105, 0.287);
}

.col-lg-3-4 {
  flex: 0 0 auto !important;
  width: 30% !important;
}

.table-row-4 {
  max-height: 250px !important;
  overflow: auto;
  display: inline-block !important;
}

.input-group-search{
  border:var(--main-color-info) solid 1px !important;
  border-radius: 10px !important;
}
.input-group-search .form-control{
  border:none !important;
  background-color: transparent !important;
  color: var(--main-color-info) !important;
}
@media (min-width: 992px) {
  .col-lg-11-mine {
    flex: 0 0 auto;
    width: 94% !important;
  }

}
.bg-Warning{
  background-color: var(--main-color-warning) !important;
}

.Mui-completed .MuiStepIcon-root{
  color: var(--main-color-info) !important;
}

.Mui-active .MuiStepIcon-root{
  background-color: var(--main-color-info) !important;
  color: var(--main-color-info) !important;
}
.Mui-active .MuiStep-root{
  background-color: var(--main-color-info) !important;
  
}

.circle {
  position:relative;
  border-radius: 50%;  
  width: 120px;
  height: 120px;
  border:#3c78bc 5px solid !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px auto;
}

.circle>img {
  position:absolute;
  width:100px;
  height:100px;
  border-radius: 50%;
  background: var(--main-color-info-secondary-background);
  margin:auto;
  top: 0; left: 0; bottom: 0; right: 0;
}

.border-info-left{
  border-left: 3px solid var(--main-color-info);
  height: 20px;
  margin-top: 10px !important;
}

a.hover-line{
  text-decoration: none;
  color: gray;
}
a.hover-line:hover{  
  border-bottom: 2px solid var(--main-color-info) !important;  
}

.button-Primary {
  background-color: #3c78bc !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #c1fcf5 !important;
  border-radius: 10px !important;
  margin: 5px !important;
  min-width: 12px !important;
}
.button-Primary:disabled {
  background-color: #cfd1d1 !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  margin: 5px !important;
  min-width: "110px";
}

.button-Primary:hover {
  background-color: #0b5fbe !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #5d89bb !important;
  border-radius: 10px !important;
  margin: 5px !important;
  min-width: "110px";
}

.button-Primary:hover:disabled {
  background-color: #cfd1d1 !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: white !important;
  box-shadow: none !important;
  
}

.button-Secondary {
  background-color: var(--main-color-info-secondary-background) !important; /**3106BB*/
  color: var(--main-color-info-secondary-accent) !important;
  height: 35px !important;
  border-radius: 10px !important;
  border: 1px solid var(--main-color-info-secondary-accent) !important;
  margin: 5px !important;
  min-width: "110px";
  box-shadow: none !important;
}

.button-Secondary:disabled {
  background-color: white !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: #cfd1d1 !important;
  height: 35px !important;
  border: #cfd1d1 1px solid !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  margin: 5px !important;
  min-width: "110px";
}

.button-Secondary:hover {
  box-shadow: 0 0.25rem 0.25rem var(--main-color-info-secondary-background) !important;
}
.button-Secondary:hover:disabled {
  background-color: white !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: #95B0B0 !important;
  height: 35px !important;
  border: #95B0B0 1px solid !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  margin: 5px !important;
  min-width: "110px";
}

.button-Success {
  background-color: #0db100 !important; /**3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #8ef586;
  border-radius: 10px !important;
  margin: 5px !important;
  min-width: "110px";
}
.button-Success:disabled {
  background-color: #5ef354 !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: rgba(255, 255, 255, 0.96) !important;
  height: 35px !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  margin: 5px !important;
  
}

.button-Success:hover {
  background-color: #175a12 !important; /**3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #0b4b07;
  border-radius: 10px !important;
  margin: 5px !important;
  
}
.button-Success:hover:disabled {
  background-color: #5ef354 !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: rgba(255, 255, 255, 0.96) !important;
  border: none !important;
  box-shadow: none !important;
  
}
.button-Success-Outlined{
  background-color: #9bf195 !important;
  color: #0db100 !important;
  height: 35px !important;
  border: none !important;
  border-radius: 10px !important;
  margin: 5px !important;
  box-shadow: none !important;
  min-width: "110px";
}
.button-Warning-Outlined{
  background-color: #ffe6c9 !important;
  color: var(--main-color-warning) !important;
  height: 35px !important;
  border: var(--main-color-warning) 1px solid !important;
  border-radius: 10px !important;
  margin: 5px !important;
  box-shadow: none !important;
  min-width: "110px";
}

.button-White-Primary {
  background-color: white !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: #3d03e1 !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #5705ff !important;
  border-radius: 10px !important;
  margin: 5px !important;
  
}

.button-White-Primary:hover {
  background-color: rgb(
    196,
    201,
    248
  ) !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: #3d03e1 !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #5705ff !important;
  border-radius: 10px !important;
  margin: 5px !important;
}

.button-White-Primary:disabled {
  background-color: rgb(
    86,
    102,
    248
  ) !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: #3d03e1 !important;
  height: 35px !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  margin: 5px !important;
}

.button-Error {
  background-color: #e10303 !important; /**3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #f87c7c;
  border-radius: 10px !important;
  margin: 5px !important;
  
}

.button-Error:disabled {
  background-color: #f87c7c !important; /**3106BB*/
  color: white !important;
  border: none !important;
  box-shadow: none !important ;
  
}

.button-Error:hover {
  background-color: #9e0303 !important; /**3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #eb1d1d;
  
}

.button-Error:hover:disabled {
  background-color: #f87c7c !important; /**3106BB*/
  color: white !important;
  border: none !important;
  box-shadow: none !important ;
  
}

.button-Error-Outlined {
  color: #e10303 !important; /**3106BB*/
  background-color: #ffd5d5 !important;
  height: 35px !important;  
  box-shadow: none !important;
  border-radius: 10px !important;
  border: #e10303 1px solid !important;
  margin: 5px !important;
}
.button-Error-Outlined *{
  color: #e10303 !important; 
}

.button-Success-Outlined {
  color: #0db100 !important; /**3106BB*/
  background-color: #b3fdad !important;
  height: 35px !important;
  border: 1px solid #0db100 !important;
  box-shadow: 0 0.25rem 0.35rem #8ef586;
  border-radius: 10px !important;
  margin: 5px !important;
  min-width: "110px";
}
.button-Success-Outlined *{
  color: #0db100 !important; /**3106BB*/
}

.button-Warning {
  background-color: #fa8500 !important; /**3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #fcd8af;
  border-radius: 10px !important;
  margin: 5px !important;
  
}

.button-Warning:disabled {
  background-color: #fcd8af !important; /**3106BB*/
  color: #fa921b !important;
  border: none !important;
  box-shadow: none !important ;
  
}

.button-Warning:hover {
  background-color: #944f00 !important; /**3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #fa921b;
  
}

.button-Warning:hover:disabled {
  background-color: #fcd8af !important; /**3106BB*/
  color: #fa921b !important;
  border: none !important;
  box-shadow: none !important ;
  
}

.button-Primary-Two {
  background-color: #9298cf !important; /**3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #b2b6d4 !important;
  border-radius: 20px !important;
  margin: 5px !important;
}

.button-Primary-Three {
  background-color: #21d2e6 !important; /**3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #8fe4ee !important;
  border-radius: 20px !important;
  margin: 5px !important;
}

.button-Primary-Two:disabled {
  background-color: #cfd1d1 !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  margin: 5px !important;
}

.button-Primary-Three:disabled {
  background-color: #cfd1d1 !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  margin: 5px !important;
}

.botonsesion{
  border-radius: 5px;
  background: #407bff;
  border: none;
  color: white;
  height: 35px;

}
.botonsesion:hover{
  border-radius: 5px;
  background: #0f264c;
  border-color: white;
  color: white;
}
#h3InicioSesion{
  color: #407bff !important;
}

#rowLoginInputAndLabel.onFocus *{
  color: #407bff !important;
}
#col-Izq-Login{
  border-right: #d7e5ff 2px solid !important;
}
#col-Izq-Login a {
  text-decoration: none;
}
#card-login .form-floating input:focus{
  background-color: #03103e !important;
  box-shadow: none !important;
  opacity: 1;
}

@media (max-width: 480px ) {
  #col-Izq-Login{
      border-right: none !important;
      /*border-left: #36148d 5px solid !important;*/
  }
  .hidOnResponsive{
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
  }
}
.w-250px{
  width: 250px !important;  
}
.button-Primary-Icon {
  background-color: #3c78bc !important; /**#4705FF-Moradito azuloso 3106BB*/
  color: white !important;
  height: 35px !important;
  border: none !important;
  box-shadow: 0 0.25rem 0.35rem #c1fcf5 !important;
  border-radius: 10px !important;
  margin: 5px !important;
}


/* Para senalar texto por abajo
.border-info-left h5 span{  
    position: relative;     
  }

.border-info-left h5 span:after{
  position: absolute;
  content: '';
  width: 35%;
  height: 1px;
  border-bottom: 2px solid var(--main-color-info);
  bottom: -2px;
  left: 50%;
  margin-left: -9.5%;
}

*/
