

#container-officeHome .a-style-cards {
    color: rgba(9, 10, 117, 0.95) !important;
    font-size: 20px;
    font-family: "Berlin Sans FB";
}
.h2-color-leftbar{
    color: rgba(9, 10, 117, 0.95) !important;
}

.bg-lightgray{
    background-color: rgba(166,168,168,0.96) !important;
}

.bg-superlightblue{
    background-color: #d2d2ff !important;
}

#text-bienv-oficina{
    color: white !important;

}
#text-subtitle-white{
    color: rgb(112, 112, 112) !important;
}

#container-officeHome{
    background-color: rgba(247,249,249,0.96) !important;
}

#card-img-fotoperfil-oficina_home {
    border: none !important;
    
}

.rowReferals {
    background-color: white !important;
    border-radius: 15px !important;
    margin-top: 20px !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.rowReferals:hover{
    background-image: linear-gradient(to right, rgba(207,226,255,0.96),white) !important;
}
.headerRowReferals{
    background-color: rgba(11,12,159,0.96) !important;
    border-radius: 15px !important;
    margin-top: 20px !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    color: white !important;

}
.w-30{
    max-width: 30% !important;
    width: 30% !important;
}
.w-40{
    max-width: 40% !important;
    width: 40% !important;
}
.w-60{
    max-width: 60% !important;
    width: 60% !important;
}
.w-70{
    max-width: 70% !important;
    width: 70% !important;
}


.headerRowReferals *{
    color: white !important;
}

#divColReferdFoto{
    background-color: rgba(185,203,232,0.96) !important;
    background-size: 80% 90%;
    background-position: center;
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;

}

#divImgPerfil {
    border: rgba(171, 187, 184, 0.96) 3px solid !important;
    height: 100px !important;
    width: 100px !important;

}

#card-saldoYlinkReferencia_OfficeHome {
    background-image: linear-gradient(to top, rgba(53, 25, 104, 0.96), darkblue);
}

.h4-title-ofic-home-cardUserDetails {
    font-size: 18px !important;
    color: rgba(57, 64, 68, 0.96)!important;

}

.h4-subtitle-ofic-home-cardUserDetails {
    font-size: 15px !important;
    color: rgba(108, 119, 117, 0.96) !important;
}

.imgPerfilUserProfile {
    margin-right: auto !important;
    margin-left: auto !important;
    height: 100px;
    width: 80px;
}

.row-oficinahome {
    margin-left: 20px;
}

#col1-saldoDisp-OfiiceHome {
    background-image: linear-gradient(to bottom,  darkblue,deepskyblue);
    margin-top: -10px;
}

#col2-saldoReten-OfiiceHome {
    background-image: linear-gradient(to bottom, purple, rgb(224, 4, 224));
    margin-top: -10px;
}

#div-button-CopyHomeOffice {
    border-radius: 25px 0 0 25px;
    background-color: #03b4cf !important;
    cursor: pointer;
}

#div-button-input-CopyHomeOffice input {
    border-radius: 0 25px 25px 0 !important;
    color: white !important;
    background-color: #03b4cf !important;
    
}

#col1-ganacias-officeHome {
    background-image: linear-gradient(to top, rgba(53, 25, 104, 0.96), darkblue);

}

#card-ganancias-officeHome {
    border-right: darkblue 6px solid;
}

#container-officeHome .card {
    border: none !important;
}

#col-button_extract_office_home input {
    background-image: linear-gradient(to top, #009fd7, darkblue) !important;
}


#row-hola-bienv-fotico img{
     margin-top: -20px !important;
}

.border-blue{
    border-left: rgba(9, 10, 117, 0.95) 3px solid !important;
}

#btn-comprar-home-office{
   background-color: rgba(9, 10, 117, 0.95) !important;
   color: white !important;
   border:none !important;
   border-radius: 10px !important;
   height: 40px !important;
}

@media (max-width: 575.98px) {
    .row-oficinahome {
        margin-left: 0px;
        justify-content: center !important;
        align-content: center !important;
        display: flex !important;
    }
    .rowReferals{
        overflow-x: scroll;
    }

    #card-ganancias-officeHome {
        border-right: none;
        flex-direction: column-reverse;
    }

    #col1-ganacias-officeHome {
        border-radius: 25px;
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }
    .footerButtonRetirarOficceHome{
        text-align: center !important;
    }
    .headerRowReferals{
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
    }
    .rowReferals h5{
        text-align: left;
        font-family: "Berlin Sans FB" !important;
        font-style: normal !important;
    }
    #divColReferdFoto{
        background-color: rgba(239,250,255,0.96) !important;
        background-size: 80% 90%;
        background-position: center;
        width: 50px;
        height: 50px;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 5px;

    }
    #divColReferdFoto .ico{
        margin-top: 5px !important;
    }
}